<template>
	<div>

		<div class="main-bg" id="mian">
			
			<div class="sub-main-w3">
				<div class="image-style">

				</div>
				<!-- vertical tabs -->
				<div class="vertical-tab">
					<div id="section1" class="section-w3ls">
						<input type="radio" name="sections" id="option1" checked>
						<article>
							<form style="height: 371px;">
								<h3 class="legend">yyle</h3>
								<div class="input">
									<span class="el-icon-user" ></span>
									<input type="text" v-model="logindata.account" placeholder="账号" name="account"
										required />
								</div>
								<div class="input">
									<span class="el-icon-key"></span>
									<input type="password" show-password v-model="logindata.pwd" placeholder="密码" name="password"
										required />
								</div>
								<div @click="submit" class="btn submit" style="text-align: center;">登 录</div>
								
							</form>
						</article>
					</div>
				</div>
				
				<div class="clear"></div>
			</div>
			
		</div>


		<!-- copyright -->
			<div class="copyright">
				<h2>
					<a target="_blank" href="https://beian.miit.gov.cn">浙ICP备2023003905号-1</a>
				</h2>
			</div>
		<!-- copyright -->

	</div>
</template>

<script>
	import { ajax } from '../../util/ajax.js';
	export default {
		name: '',
		components: {},
		data() {
			return {
				nowYear: new Date().getFullYear(),
				logindata: {
					account: "",
					pwd: ""
				},

			}
		},
		mounted() {

		},
		methods: {

			//登录
			async submit(){
			
			
			
				let { account,pwd } = this.logindata;
				if(account.trim()==''){
					this.$message.error('请填写账号');
					return
				}
				if(pwd.trim()==''){
					this.$message.error('请填写密码');
					return
				}
				//登录
				const res1 = await ajax('/admin/login','POST',{username:account,password:pwd},{})
				localStorage.setItem('token',res1.data.data.token);
				
				this.$message({message:'登录成功',type: 'success'});
				localStorage.setItem('nickname',res1.data.data.user.username);
				localStorage.setItem('avatar',res1.data.data.user.avatar);
				localStorage.setItem('role','3alxi2fdksjfkljwijljsk128ldfln324kodnvjdsl32432jfiwefjls32432idaxpoife983iw2'+res1.data.data.user.role);//当前用户角色权限标识名称
				
				this.$router.push({path:'/'});
			},
			
		}
	}
</script>

<style scoped>
	html,
	body,
	#app {
		height: 100%;
	}


	/*--
	Author: W3layouts
	Author URL: http://w3layouts.com
	License: Creative Commons Attribution 3.0 Unported
	License URL: http://creativecommons.org/licenses/by/3.0/
	--*/

	/* reset */
	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	dl,
	dt,
	dd,
	ol,
	nav ul,
	nav li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	copyright,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	article,
	aside,
	details,
	figcaption,
	figure,
	copyright,
	header,
	hgroup,
	menu,
	nav,
	section {
		display: block;
	}

	ol,
	ul {
		list-style: none;
		margin: 0px;
		padding: 0px;
	}

	blockquote,
	q {
		quotes: none;
	}

	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: '';
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/* start editing from here */
	a {
		text-decoration: none;
	}

	.txt-rt {
		text-align: right;
	}

	/* text align right */
	.txt-lt {
		text-align: left;
	}

	/* text align left */
	.txt-center {
		text-align: center;
	}

	/* text align center */
	.float-rt {
		float: right;
	}

	/* float right */
	.float-lt {
		float: left;
	}

	/* float left */
	.clear {
		clear: both;
	}

	/* clear float */
	.pos-relative {
		position: relative;
	}

	/* Position Relative */
	.pos-absolute {
		position: absolute;
	}

	/* Position Absolute */
	.vertical-base {
		vertical-align: baseline;
	}

	/* vertical align baseline */
	.vertical-top {
		vertical-align: top;
	}

	/* vertical align top */
	nav.vertical ul li {
		display: block;
	}

	/* vertical menu */
	nav.horizontal ul li {
		display: inline-block;
	}

	/* horizontal menu */
	img {
		max-width: 100%;
	}

	/* //end reset */

	body {
		font-size: 100%;
	}

	.main-bg {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		background-repeat: no-repeat;
		background-position: center;


		/* 这个接口是源代码主人的 ,请求一次变一次图片 */
		/* background-image: url("https://picsum.photos/1920/1080"); */
		background-image: url("../../assets/login-bg.jpg");

		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		-ms-background-size: cover;
		min-height: 100vh;
		background-color: #262626;
	}

	/* title */
	h1 {
		font-size: 3vw;
		color: #fff;
		text-align: center;
		padding: 2.5vw 1vw 1vw;
		letter-spacing: 3px;
		text-transform: uppercase;
		font-weight: 600;
	}

	/* //title */

	.sub-main-w3 {
		border-radius:10px;
		width: 770px;
		overflow: hidden;
		padding-left: 30px;
		background: #fff;
	}

	.image-style {
		background: url('../../assets/logo.png') no-repeat center;
		min-height: 371px;
		background-size: contain;
		background-color: #fff;
		background-size: 100px 100px;
	}

	/* vertical tabs */
	.vertical-tab {
		color: #333;
		display: block;
		margin: auto;
		position: relative;
		float: left;
		background: #fff;

	}

	.image-style {
		float: left;
		width: 25%;
	}

	.vertical-tab input[name="sections"] {
		left: -9999px;
		position: absolute;
		top: -9999px;
	}

	.vertical-tab .section-w3ls {
		display: block;
	}

	.vertical-tab .section-w3ls label {
		background: #fff;
		border-right: 1px solid #dcdcdc;
		cursor: pointer;
		display: block;
		font-size: 17px;
		padding: 33px 20px;
		position: relative;
		width: 231px;
		/* height: 124px; */
		z-index: 100;
		color: #111;
		text-align: center;
	}

	.vertical-tab .section-w3ls article {
		display: none;

		min-width: 570px;
		position: absolute;
		top: 0;
	}

	.vertical-tab input[name="sections"]:checked+label {
		background: #00ad45;
		border-right: 1px solid #000;
		color: #fff;
	}

	.vertical-tab input[name="sections"]:checked~article {
		display: block;
	}

	label.icon-left-w3pvt span {
		display: block;
		font-size: 30px;
		margin-bottom: 7.8px;
	}

	/* //vertical tabs */

	/* form style */
	.sub-main-w3 form {
	    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
		background: #ffff;
		padding: 2em;
	}

	.legend {
		color: #000;
		font-size: 24px;
		text-align: center;
		margin-bottom: 1.2em;
		font-weight: 400;
	}

	p.para-style {
		margin-bottom: .8em;
		font-size: 15px;
		letter-spacing: .5px;
		line-height: 1.8;
	}

	p.para-style-2 {
		font-size: 15px;
		letter-spacing: .5px;
		line-height: 1.8;
		margin-bottom: 2em;
	}

	p.para-style-2 a {
		color: blue;
	}

	.input {
		margin-bottom: 1em;
		padding: 13px 15px;
		border: 1px solid #eee;
		background: #eee;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.input span {
		font-size: 15px;
		color: #464646;
		margin-right: 10px;
	}

	.input input {
		color: #000;
		font-size: 14px;
		letter-spacing: 1px;
		box-sizing: border-box;
		border: none;
		outline: none;
		background: transparent;
		width: 100%;
	}

	.submit {
		margin: 2.3em auto 0;
		background: #00ad45;
		border: none;
		cursor: pointer;
		font-weight: 600;
		-webkit-transition: 0.5s all;
		-o-transition: 0.5s all;
		-moz-transition: 0.5s all;
		-ms-transition: 0.5s all;
		transition: 0.5s all;
		display: block;
		color: #fff;
		padding: 14px 30px;
		-webkit-border-radius: 0;
		-o-border-radius: 0;
		-ms-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		font-size: 15px;
		letter-spacing: 2px;
		width: 100%;
	}

	.submit:hover {
		opacity: .8;
		-webkit-transition: 0.5s all;
		-o-transition: 0.5s all;
		-moz-transition: 0.5s all;
		-ms-transition: 0.5s all;
		transition: 0.5s all;
	}

	a.bottom-text-w3ls {
		color: #757474;
		font-size: 14px;
		display: inherit;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 3.2em;
	}

	/* //form style */

	/* copyright */
	.copyright {
		position:fixed;
		bottom:20px;
		left:50%;
		transform: translateX(-50%);
		
	}

	.copyright h2 {
		font-size: 15px;
		color: #fff;
		letter-spacing: 1px;
		text-align: center;
		line-height: 1.8;
	}

	.copyright h2 a {
		color: #fff;
		font-weight: bold;
		-webkit-transition: 0.5s all;
		-o-transition: 0.5s all;
		-moz-transition: 0.5s all;
		-ms-transition: 0.5s all;
		transition: 0.5s all;
	}

	.copyright h2 a:hover {
		opacity: .8;
		-webkit-transition: 0.5s all;
		-o-transition: 0.5s all;
		-moz-transition: 0.5s all;
		-ms-transition: 0.5s all;
		transition: 0.5s all;
	}
</style>
